import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import productReducer from './slices/product';
import invoiceReducer from './slices/invoice';
import customerReducer from './slices/customer';
import offlineOrderReducer from './slices/offline-order';
import onlineOrderReducer from './slices/online-order';
import orderStatusReducer from './slices/order-status';
import orderArchiveReducer from './slices/order-archive';
import commandCentralReducer from './slices/command-central';
import hotProductsReducer from './slices/hotProducts';
import adminPanelReducer from './slices/admin-panel';
import inventoryReducer from './slices/inventory';
import sellGptReducer from './slices/sellGpt';
import inventoryCatalogue from './slices/inventory-catalogue';
import tutorialStatusesReducer from './slices/tutorial-statuses';
import procurementReducer from './slices/procurement';
import subscription from './slices/subscription';
import accountSetting from './slices/account-setting';
import taxArchive from './slices/tax-archive';
import { apiSlice } from './rtkq/apiSlice';
import perfomance from './slices/perfomance';
import batchTracking from './slices/batch-tracking';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
  // Needed for RTK query compatability with redux-persist
  blacklist: [apiSlice.reducerPath],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const invoicePersistConfig = {
  key: 'invoice',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'filterBy'],
};

const offlineOrderPersistConfig = {
  key: 'offlineOrder',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const onlineOrderPersistConfig = {
  key: 'onlineOrder',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const orderStatusPersistConfig = {
  key: 'orderStatus',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const orderArchivePersistConfig = {
  key: 'orderArchive',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const commandCentralPersistConfig = {
  key: 'commandCentral',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const hotProductsPersistConfig = {
  key: 'hotProducts',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const adminPanelPersistConfig = {
  key: 'adminPannel',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const inventoryPersistConfig = {
  key: 'inventory',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const sellGptPersistConfig = {
  key: 'sellGpt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const inventoryCataloguePersistConfig = {
  key: 'inventoryCatalogue',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const tutorialStatusesPersistConfig = {
  key: 'tutorialStatuses',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const procurementPersistConfig = {
  key: 'procurement',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const subscriptionPersistConfig = {
  key: 'subscription',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const accountSettingPersistConfig = {
  key: 'accountSetting',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const taxArchivePersistConfig = {
  key: 'taxArchive',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const performancePersistConfig = {
  key: 'perfomance',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const batchTrackingConfig = {
  key: 'batchTracking',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};


const rootReducer = combineReducers({
  product: persistReducer(productPersistConfig, productReducer),
  invoice: persistReducer(invoicePersistConfig, invoiceReducer),
  customer: persistReducer(customerPersistConfig, customerReducer),
  offlineOrder: persistReducer(offlineOrderPersistConfig, offlineOrderReducer),
  onlineOrder: persistReducer(onlineOrderPersistConfig, onlineOrderReducer),
  orderStatus: persistReducer(orderStatusPersistConfig, orderStatusReducer),
  orderArchive: persistReducer(orderArchivePersistConfig, orderArchiveReducer),
  commandCentral: persistReducer(commandCentralPersistConfig, commandCentralReducer),
  hotProducts: persistReducer(hotProductsPersistConfig, hotProductsReducer),
  adminPanel: persistReducer(adminPanelPersistConfig, adminPanelReducer),
  inventory: persistReducer(inventoryPersistConfig, inventoryReducer),
  sellGpt: persistReducer(sellGptPersistConfig, sellGptReducer),
  inventoryCatalogue: persistReducer(inventoryCataloguePersistConfig, inventoryCatalogue),
  tutorialStatuses: persistReducer(tutorialStatusesPersistConfig, tutorialStatusesReducer),
  procurement: persistReducer(procurementPersistConfig, procurementReducer),
  subscription: persistReducer(subscriptionPersistConfig, subscription),
  accountSetting: persistReducer(accountSettingPersistConfig, accountSetting),
  taxArchive: persistReducer(taxArchivePersistConfig, taxArchive),
  performance:persistReducer(performancePersistConfig, perfomance),
  batchTracking:persistReducer(batchTrackingConfig, batchTracking),
  // Needed for RTK query integration
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export { rootPersistConfig, rootReducer };
