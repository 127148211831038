import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  highLevel: {
    filters: {
      tag: '',
      productCategoryTag: '',
    },
  },
  detailLevel: {
    filters: {
      productCategoryTag: '',
    },
  },

  modalConfig: {
    isOpen: false,
    type: 'default',
    data: {},
  },

  warehouses: [],
  detailLevelWarehouse: null,

  isDetailLevel: false,

  addInventoryItemModal: {
    products: [],
    selectedProductIds: [],
    isLoading: false,
    cellQuantity: {},
    cellUnitPrice: {}, 
    cellLowStockThreshold: {},
    alertConfig: {
      open: false,
      message: '',
      severity: 'success',
    },
    cellBatchQuantity: {},
    cellBatchExpiration: {},
    cellBatchName: {},
  },

};

/**  @param {{ payload: {}, keys: string[] }} param */
const validatePayloadKeys = ({ payload, keys }) => {
  const payloadKeys = Object.keys(payload);
  keys.forEach((key) => {
    if (!payloadKeys.includes(key)) throw new Error(`Missing key: ${key}`);
  });
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setWarehouses(state, action) {
      state.warehouses = action.payload;
    },
    setWarehouse(state, action) {
      const { warehouseId, warehouse } = action.payload;
      const warehouseIndex = state.warehouses.findIndex((warehouse) => warehouse.warehouseId === warehouseId);
      state.warehouses[warehouseIndex] = warehouse;
    },

    /** @param {{ payload: { warehouseId: string, products: [] } }} action */
    updateWarehouseProducts(state, action) {
      const { warehouseId, products } = action.payload;
      const warehouseIndex = state.warehouses.findIndex((warehouse) => warehouse.warehouseId === warehouseId);
      state.warehouses[warehouseIndex].products = products;
    },
    /** @param {{ payload: { warehouseId: string, productId: string, quantity: number, lowStockThreshold: number } }} action */
    updateWarehouseProduct(state, action) {
      const { warehouseId, productId, quantity, lowStockThreshold } = action.payload;
      const warehouseIndex = state.warehouses.findIndex((warehouse) => warehouse.warehouseId === warehouseId);
      const productIndex = state.warehouses[warehouseIndex].products.findIndex(
        (product) => product.productId === productId,
      );
      const product = state.warehouses[warehouseIndex].products[productIndex];
      if (!product) {
        const newProduct = state.addInventoryItemModal.products.find((product) => product.productId === productId);
        state.warehouses[warehouseIndex].products.push({
          ...newProduct,
          quantity: Number(quantity),
          lowStockThreshold,
        });
        return;
      }
      state.warehouses[warehouseIndex].products[productIndex].quantity = Number(quantity) + Number(product.quantity);
      state.warehouses[warehouseIndex].products[productIndex].lowStockThreshold = lowStockThreshold;
    },

    setDetailLevelWarehouse(state, action) {
      state.detailLevelWarehouse = action.payload;
    },

    /** @param {{ payload: { tag: 'string', productCategoryTag: 'string' } }} action */
    setHighLevelFilter(state, action) {
      state.highLevel.filters = action.payload;
    },
    /**  @param {{ payload: { productCategoryTag: 'string' } }} action  */
    setDetailLevelFilter(state, action) {
      state.detailLevel.filters = action.payload;
    },
    /**  @param {{ payload: boolean }} action  */
    setIsDetailLevel(state, action) {
      state.isDetailLevel = action.payload;
    },

    /** @param {{ payload: { isOpen: boolean, type: string, data: {} } }} action */
    setModalConfig(state, action) {
      state.modalConfig = action.payload;
    },

    closeModal(state) {
      state.modalConfig = {
        isOpen: false,
        type: 'default',
        data: {},
      };
    },

    /** @param {{ payload: "remove" | "default" }} action */
    setModalType(state, action) {
      state.modalConfig.isOpen = true;
      state.modalConfig.type = action.payload;
    },

    /** @param {{ payload: { productId: string, productName: string, quantity: number, warehouseId: string, warehouseName: string } }} action */
    setRemoveModalData(state, action) {
      validatePayloadKeys({
        payload: action.payload,
        keys: ['productId', 'productName', 'quantity', 'warehouseId', 'warehouseName'],
      });
      state.modalConfig.data = action.payload;
    },

    /** @param {{ payload: { productId: string, warehouseId: string } }} action */
    deleteProduct(state, action) {
      const { productId, warehouseId } = action.payload;
      const warehouseIndex = state.warehouses.findIndex((warehouse) => warehouse.warehouseId === warehouseId);
      const productIndex = state.warehouses[warehouseIndex].products.findIndex(
        (product) => product.productId === productId,
      );
      state.warehouses[warehouseIndex].products.splice(productIndex, 1);
    },

    // addInventoryItemModal
    setAddInventoryItemModalProducts(state, action) {
      state.addInventoryItemModal.products = action.payload;
    },
    setAddInventoryItemModalIsLoading(state, action) {
      state.addInventoryItemModal.isLoading = action.payload;
    },

    updateAddInventoryItemModalProduct(state, action) {
      const newProduct = action.payload;
      const productIndex = state.addInventoryItemModal.products.findIndex(
        (product) => product.productId === newProduct.productId,
      );
      state.addInventoryItemModal.products[productIndex] = newProduct;
    },

    setAddInventoryItemModalSelectedProductIds(state, action) {
      state.addInventoryItemModal.selectedProductIds = action.payload;
    },

    setAddInventoryItemModalCellQuantity(state, action) {
      state.addInventoryItemModal.cellQuantity = action.payload;
    },

    setAddInventoryItemModalCellUnitPrice(state, action) {
      state.addInventoryItemModal.cellUnitPrice = action.payload;
    },

    setAddInventoryItemModalCellLowStockThreshold(state, action) {
      state.addInventoryItemModal.cellLowStockThreshold = action.payload;
    },
    /** @param {{ payload: { open: boolean, message: string, severity: "success" | "error" | "warning" | "info" } }} action */
    setAddInventoryItemModalAlertConfig(state, action) {
      state.addInventoryItemModal.alertConfig = action.payload;
    },

    setAddInventoryItemModalCellBatchQuantity(state, action) {
      state.addInventoryItemModal.cellBatchQuantity = action.payload;
    },

    setAddInventoryItemModalCellBatchExpiration(state, action) {
      state.addInventoryItemModal.cellBatchExpiration = action.payload;
    },

    setAddInventoryItemModalCellBatchName(state, action) {
      state.addInventoryItemModal.cellBatchName = action.payload;
    },
  },
});

export const {
  setHighLevelFilter,
  setDetailLevelFilter,
  setIsDetailLevel,
  setModalConfig,
  closeModal,
  setModalType,
  setRemoveModalData,
  setWarehouses,
  setWarehouse,
  updateWarehouseProducts,
  updateWarehouseProduct,
  setDetailLevelWarehouse,
  deleteProduct,
  setAddInventoryItemModalProducts,
  setAddInventoryItemModalIsLoading,
  updateAddInventoryItemModalProduct,
  setAddInventoryItemModalSelectedProductIds,
  setAddInventoryItemModalCellQuantity,
  setAddInventoryItemModalCellUnitPrice,
  setAddInventoryItemModalCellLowStockThreshold,
  setAddInventoryItemModalAlertConfig,
  setAddInventoryItemModalCellBatchQuantity,
  setAddInventoryItemModalCellBatchExpiration,
  setAddInventoryItemModalCellBatchName,
} = slice.actions;

export default slice.reducer;

/** @returns {{ tag: string, productCategoryTag: string }} */
export const selectHighLevelFilters = (state) => state.inventory.highLevel.filters;
/** @returns {{ productCategoryTag: string }} */
export const selectDetailLevelFilters = (state) => state.inventory.detailLevel.filters;
/** @returns { boolean } */
export const selectIsDetailLevel = (state) => state.inventory.isDetailLevel;
/** @returns {{ isOpen: boolean, type: string, data: {} }} */
export const selectModalConfig = (state) => state.inventory.modalConfig;
export const selectWarehouses = (state) => state.inventory.warehouses;

export const selectDetailLevelWarehouse = (state) => state.inventory.detailLevelWarehouse;

// addInventoryItemModal
export const selectAddInventoryItemModalProducts = (state) => state.inventory.addInventoryItemModal.products;
export const selectAddInventoryItemModalIsLoading = (state) => state.inventory.addInventoryItemModal.isLoading;

export const selectAddInventoryItemModalSelectedProductIds = (state) =>
  state.inventory.addInventoryItemModal.selectedProductIds;

export const selectAddInventoryItemModalCellQuantity = (state) => state.inventory.addInventoryItemModal.cellQuantity;

export const selectAddInventoryItemModalCellUnitPrice = (state) => state.inventory.addInventoryItemModal.cellUnitPrice;

export const selectAddInventoryItemModalCellLowStockThreshold = (state) =>
  state.inventory.addInventoryItemModal.cellLowStockThreshold;

export const selectAddInventoryItemModalAlertConfig = (state) => state.inventory.addInventoryItemModal.alertConfig;

export const selectAddInventoryItemModalCellBatchQuantity = (state) => state.inventory.addInventoryItemModal.cellBatchQuantity;

export const selectAddInventoryItemModalCellBatchExpiration = (state) =>
  state.inventory.addInventoryItemModal.cellBatchExpiration;

export const selectAddInventoryItemModalCellBatchName = (state) => state.inventory.addInventoryItemModal.cellBatchName;