import { createSlice } from '@reduxjs/toolkit';
import { getBatchTracking } from 'src/server/awsClient';

const initialState = {
  batchTrackingList: [],
  loading: false,
  error: null,
};

const batchTrackingSlice = createSlice({
  name: 'batchTracking',
  initialState,
  reducers: {
    setBatchTrackingList(state, action) {
      state.batchTrackingList = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

// Thunk action to fetch batch data from API
export const fetchBatchTrackingData = (id) => async (dispatch) => {
  dispatch(setLoading(true)); // Set loading to true before API call
  try {
    const response = await getBatchTracking(id); // Replace with your actual API call
    dispatch(setBatchTrackingList(response.data.batches)); // Set batch data in state
  } catch (error) {
    dispatch(setError(error.message)); // Handle error if API call fails
  } finally {
    dispatch(setLoading(false)); // Set loading to false after the API call
  }
};

// Action creators exported from the slice
export const { setBatchTrackingList, setLoading, setError } = batchTrackingSlice.actions;

// Selector to select batchTrackingList from the Redux state
export const selectBatchTrackingList = (state) => state.batchTracking.batchTrackingList;
export const selectBatchLoading = (state) => state.batchTracking.loading;
export const selectBatchError = (state) => state.batchTracking.error;

// Reducer exported from the slice
export default batchTrackingSlice.reducer;
