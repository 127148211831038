import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useLocales from 'src/hooks/useLocales';
import { useEffect, useState } from 'react';
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  Stack,
} from "@mui/material";
import Logo from "../../components/Logo";
import i18next from 'i18next';

// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------
export default function MainFooter() {
  // Imported the language, to basically determine which link to give for a download based on language selected for the terms and privacy policy
  const { currentLang } = useLocales();
  const [language, setLanguage] = useState('');

  useEffect(() => {
    if (currentLang.value === 'en' || currentLang.value === 'kr') {
      setLanguage(currentLang.value);
    } else {
      setLanguage('en');
    }
  }, [currentLang.value]);

  const LINKS = [
    {
      headline: i18next.t('landing.mainFooter.links.headline1'),
      children: [
        { name: i18next.t('landing.menu.button1'), href: "/" },
        // { name: i18next.t('landing.menu.button2'), href: "/about" },
        { name: i18next.t('landing.mainFooter.links.name5'), href: "/pricing" },
      ],
    },
    {
      headline: i18next.t('landing.mainFooter.links.headline2'),
      children: [
        { name: i18next.t('landing.mainFooter.links.name2'), href: `https://spbeta.s3.amazonaws.com/static/terms-policies-pdf/terms_and_conditions_${language}.pdf`,external: true },
        { name: i18next.t('landing.mainFooter.links.name3'), href: `https://spbeta.s3.amazonaws.com/static/terms-policies-pdf/privacy_policy_${language}.pdf`, external: true },
      ],
    },
    {
      headline: i18next.t('landing.mainFooter.links.headline3'),
      children: [
        { name: i18next.t('landing.mainFooter.links.name4'), href: "#", text: true },
        { name: i18next.t('landing.mainFooter.links.name10'), href: "#", text: true },
        // { name: i18next.t('landing.mainFooter.links.name9'), href: "#", text: true },
      ],
    },
  ];
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: -3 }}>
        <Grid
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Grid item xs={12} sx={{ mb: -5, mt: -3 }}>
            <RouterLink to="/">
              <Logo sx={{ mx: { xs: "auto", md: "inherit" } }} />
            </RouterLink>
          </Grid>
          <Grid item xs={8} md={5}>
            <Typography variant="body2" sx={{ pr: { md: 5 }, paddingBottom: 2 }}>
              {i18next.t('landing.mainFooter.text1')}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              sx={{
                textAlign: { xs: "center", md: "left" },
              }}
            >
              {LINKS.map((list, index) => {
                const { headline, children } = list;
                return (
                  <Stack
                    key={headline}
                    spacing={1.5}
                    sx={{
                      paddingTop: { xs: `${index === 1 ? 53 : 50}px`, md: 0 }
                    }}
                  >
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => {
                      const linkProps = link.external
                        ? {
                            href: link.href,
                            target: "_blank",
                            rel: "noopener noreferrer",
                          }
                        : {
                            to: link.href,
                            component: RouterLink,
                          };

                      return (
                        <Link
                          key={link.name}
                          {...linkProps}
                          color="inherit"
                          variant="body2"
                          sx={{
                            display: 'block',
                            maxWidth: { md: "240px" },
                            textDecoration: "none",
                            fontSize: 14,
                            paddingTop: 2
                          }}
                          style={{
                            color: 'inherit',
                          }}
                        >
                          {link.name}
                        </Link>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 6.8,
            pb: 6,
            fontSize: 12,
            textDecoration: "none",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {i18next.t('landing.mainFooter.text2')}
        </Typography>
      </Container>
    </RootStyle>
  );
}
